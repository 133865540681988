module.exports = [{
      plugin: require('/Users/stevegenoud/workbench/horaires-web/node_modules/gatsby-remark-autolink-headers/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/stevegenoud/workbench/horaires-web/node_modules/gatsby-remark-images/gatsby-browser'),
      options: {"plugins":[],"webP":true,"maxWidth":590},
    },{
      plugin: require('/Users/stevegenoud/workbench/horaires-web/node_modules/gatsby-plugin-fathom/gatsby-browser'),
      options: {"plugins":[],"trackingUrl":"fathom.horair.es","siteId":"HFNUW"},
    },{
      plugin: require('/Users/stevegenoud/workbench/horaires-web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
